import { CreatorService } from '.';
import {
  CustomerService,
  ProfileService,
  ProductService,
  VatCodeService,
  LedgerNumberService,
  EmailTextService,
} from '@/lib/services';
import {
  InvoiceReceiver,
  Product,
  Profile,
  InvoiceVAT,
  LedgerNumber,
  InvoiceTextEmail,
  Customer,
} from '@/models';

export default class TniCreatorService implements CreatorService {
  async getLogo(logo: string): Promise<any> {
    return ProfileService.getLogo(logo);
  }

  async listReceivers(): Promise<InvoiceReceiver[]> {
    return (await CustomerService.all()).map((e: Customer) =>
      e.toInvoiceReceiver(),
    );
  }

  async listProfiles(): Promise<Profile[]> {
    return ProfileService.all();
  }

  async searchProducts(keyword: string): Promise<Product[]> {
    const products = await ProductService.search(0, {
      term: keyword,
      limit: 5,
    });

    return products.items;
  }

  async listLedgerNumbers(): Promise<LedgerNumber[]> {
    return LedgerNumberService.all();
  }

  async listVatCodes(): Promise<InvoiceVAT[]> {
    return VatCodeService.all();
  }

  async emailText(emailTextId: number): Promise<InvoiceTextEmail> {
    const text = await EmailTextService.get(emailTextId);

    const emailData = new InvoiceTextEmail();
    emailData.subject = text.subject;
    emailData.body = text.text;

    return emailData;
  }

  async createCustomer(customer: Customer): Promise<number> {
    return CustomerService.create(customer);
  }

  async updateCustomer(customer: Customer): Promise<void> {
    return CustomerService.update(customer);
  }
}
