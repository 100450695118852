





















import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import {
  Invoice,
  InvoiceReceiver,
  InvoiceStatus,
  InvoiceTextEmail,
} from '@/models/invoice';
import { Profile } from '@/models';

import { CreatorService } from '@/lib/services/meta/creator';
import TniCreatorService from '@/lib/services/meta/creator/tni';
import { InvoiceCreator } from '@/components/invoicecreator';
import { headOrSelf, unwrapError } from '@/lib/helpers';
import {
  CustomerService,
  InvoiceService,
  MatomoService,
  ProfileService,
} from '@/lib/services';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import { InvoiceSendType } from '@/lib/services/invoices';
import { downloadBlob } from '@/lib/download';
import { TrackingEvent } from '@/lib/trackingevent';
import { defaultsDeep } from 'lodash-es';
import InvoiceCreatorConfig, {
  DEFAULT_CONFIG,
} from '@/components/invoicecreator/config';

const EDITABLE_INVOICES = [
  InvoiceStatus.DRAFT,
  InvoiceStatus.RECURRING,
  InvoiceStatus.DRAFT_QUOTATION,
];

const QUOTATION_TYPES = [
  InvoiceStatus.QUOTATION,
  InvoiceStatus.DRAFT_QUOTATION,
  InvoiceStatus.ARCHIVED_QUOTATION,
];

@Component({
  components: {
    SendInvoiceModal,
    InvoiceCreator,
  },
})
export default class InvoiceCreate extends Vue {
  @Provide() creatorService: CreatorService = new TniCreatorService();

  config: InvoiceCreatorConfig = {};
  invoice: Invoice = new Invoice();
  initProfile: Profile | null = null;

  isEditing: boolean = false;
  isModifying: boolean = false;
  loading: boolean = true;
  showSendModal: boolean = false;
  typeMapping = {
    invoice: InvoiceStatus.DRAFT,
    quotation: InvoiceStatus.DRAFT_QUOTATION,
    recurring: InvoiceStatus.RECURRING,
    draft: InvoiceStatus.DRAFT,
  };

  $refs: {
    creator: InvoiceCreator;
  };

  async created(): Promise<void> {
    defaultsDeep(this.config, DEFAULT_CONFIG);

    try {
      const editId = this.$route.query['edit']
        ? headOrSelf(this.$route.query['edit'])
        : null;
      const modifyId =
        editId ||
        (this.$route.query['copy']
          ? headOrSelf(this.$route.query['copy'])
          : null);
      const type = this.$route.query['type']
        ? headOrSelf(this.$route.query['type'])!
        : null;

      // For quotations to adopt a new footer
      let resetFooter = false;

      if (type && this.config.allowedTypes!.includes(this.typeMapping[type])) {
        this.invoice.status = this.typeMapping[type];
      }
      if (editId) this.isEditing = true;
      if (modifyId) {
        this.isModifying = true;
        const modifyInvoice = await InvoiceService.get(modifyId);
        if (this.isEditing) {
          if (!EDITABLE_INVOICES.includes(modifyInvoice.status)) {
            this.$toaster.error(
              this.$tc('messages.error.setup.creator'),
              'Cannot edit this invoice',
            );
            return;
          }

          if (modifyInvoice.isRecurring) {
            modifyInvoice.meta.date = modifyInvoice.meta.recurring.startDate;
          }

          if (
            modifyInvoice.status === InvoiceStatus.DRAFT ||
            modifyInvoice.status === InvoiceStatus.DRAFT_QUOTATION
          ) {
            // We are editing a draft invoice. Per #229, shift the send date to today
            modifyInvoice.meta.date = new Date();
          }
        } else {
          // Revert status back to their 'draft' equivalent
          if (QUOTATION_TYPES.includes(modifyInvoice.status)) {
            modifyInvoice.status = InvoiceStatus.DRAFT_QUOTATION;
          } else if (modifyInvoice.status === InvoiceStatus.RECURRING) {
            modifyInvoice.status = InvoiceStatus.RECURRING;
          } else {
            modifyInvoice.status = InvoiceStatus.DRAFT;
          }

          modifyInvoice.meta.date = new Date();

          // If we are converting, we go and make it a normal draft
          if (
            this.$route.query['convert'] &&
            modifyInvoice.status === InvoiceStatus.DRAFT_QUOTATION
          ) {
            modifyInvoice.status = InvoiceStatus.DRAFT;
            resetFooter = true;
          }
        }
        await this.populateFromInvoice(modifyInvoice, resetFooter);
        this.loading = false;
      }
      this.loading = false;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.setup.creator'),
        unwrapError(e),
      );
    }
  }

  async onSave(invoice: Invoice): Promise<void> {
    const id = await this.createOrUpdate(invoice);
    const updateType = this.isEditing ? 'update' : 'created';
    this.$toaster.success(
      this.$tc(`messages.success.${updateType}.invoice`),
      id,
    );
    await this.reinitialize();
  }

  async onFinish(): Promise<void> {
    this.showSendModal = true;
  }

  async createOrUpdate(invoice: Invoice): Promise<string> {
    let result: string;
    let event: TrackingEvent;
    if (this.isEditing) {
      result = await InvoiceService.update(invoice);
      event = TrackingEvent.invoice().create(invoice.trackingType, result);
    } else {
      result = await InvoiceService.create(invoice);
      event = TrackingEvent.invoice().create(invoice.trackingType, result);
    }
    MatomoService.trackEvent(event);
    return result;
  }

  async populateFromInvoice(
    invoice: Invoice,
    resetFooter = false,
  ): Promise<void> {
    this.invoice = invoice;

    const savedText = {
      top: invoice.text.top,
      bottom: invoice.text.bottom,
      footer: invoice.text.footer,
    };

    try {
      this.invoice.receiver = (
        await CustomerService.get(invoice.receiver.id)
      ).toInvoiceReceiver();
    } catch (e) {
      // Customer has been deleted, updated information could not be obtained.
      // In this case, we just clear the receiver and let the user select
      // a different customer.
      this.invoice.receiver = new InvoiceReceiver();
      this.$toaster.warning(this.$tc('messages.error.load.customer'));
    }

    try {
      // Ok, so here we botch the actual profile
      // To set the text fields to our wanted values
      // Together with the selector not re-applying profiles, this will actually
      // make it so that the text *should* get applied without a race condition
      const profile = await ProfileService.get(+invoice.sender.id);
      profile.extraText = savedText.top;
      profile.extraTextBottom = savedText.bottom;
      profile.expiration = invoice.meta.term;

      if (!resetFooter) {
        profile.footer = savedText.footer;
      }

      this.initProfile = profile;
    } catch (e) {
      // Ignorable error
    }

    // A bit superfluous but cannot hurt to explicitly set this anyway
    Object.assign(invoice.text, savedText);
  }

  async reinitialize(): Promise<void> {
    // To **force** refresh the creator and all child components, we force it to be unloaded
    // by setting loading to true.
    // At the end of this function, we will set loading back to false.
    this.loading = true;

    if (this.isEditing || this.isModifying) {
      await this.$router.push({ name: 'invoice_create', query: {} });
      this.isEditing = this.isModifying = false;
    }

    const savedProperties = {
      sender: this.invoice.sender,
      language: this.invoice.meta.language,
    };
    this.invoice = new Invoice();
    this.invoice.sender = savedProperties.sender;
    this.invoice.meta.language = savedProperties.language;
    try {
      await this.$refs.creator.onProfileChanged(
        await ProfileService.get(+this.invoice.sender.id),
      );
    } catch (e) {
      // Ignorable error
    }
    // Set loading back to false, but do it in the next render cycle
    // This forces vue to render the v-if="loading" for at least a tick,
    // forcing vue to unload everything and thus to recreate everything next cycle
    this.$nextTick(() => (this.loading = false));
  }

  async onInvoiceSend(
    type: string,
    invoice: Invoice,
    text: InvoiceTextEmail,
  ): Promise<boolean> {
    try {
      const id = await this.createOrUpdate(invoice);
      if (!id) {
        this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
        return false;
      }

      // Mark invoice as sent
      await InvoiceService.send(id, InvoiceSendType.Mark);

      if (type === 'email') {
        // Email it
        await InvoiceService.send(id, InvoiceSendType.Mail, { text });
        MatomoService.trackEvent(
          TrackingEvent.invoice().email(invoice.trackingType, id),
        );
        this.$toaster.success(
          this.$tc('messages.success.send.invoice.title'),
          this.$tc('messages.success.send.invoice.body'),
        );
      } else if (type === 'sms') {
        await InvoiceService.send(id, InvoiceSendType.Sms);
        MatomoService.trackEvent(
          TrackingEvent.invoice().sms(invoice.trackingType, id),
        );
        this.$toaster.success(
          this.$tc('messages.success.send.invoice.title'),
          this.$tc('messages.success.send.invoice.body'),
        );
      } else {
        // Download it
        const response = await InvoiceService.sendDownloadPdf(id);
        await downloadBlob(response);
        MatomoService.trackEvent(
          TrackingEvent.invoice().download(invoice.trackingType, id),
        );
        this.$toaster.success(
          this.$tc('messages.success.save.invoice.title'),
          this.$tc('messages.success.save.invoice.body'),
        );
      }

      await this.reinitialize();
      this.showSendModal = false;
      return true;
    } catch (e) {
      this.$toaster.error(
        this.$tc(
          `messages.error.${type === 'email' ? 'send' : 'save'}.invoice`,
        ),
        unwrapError(e),
      );
      return false;
    }
  }
}
